import {
  type ClientLoaderFunctionArgs,
  Outlet,
  type ShouldRevalidateFunctionArgs,
  useRouteLoaderData,
} from '@remix-run/react';
import { IntercomProvider } from 'react-use-intercom';
import { $routeId } from 'remix-routes';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';

import { useUserAnalytics } from '../analytics/user';
import {} from '../components/Access';
import { GlobalBlockedAccess } from '../components/Access/GlobalBlocked';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GamePackUtils } from '../components/Game/GamePack/utils';
import { GameCoverClip } from '../components/Game/GamePackCoverPres';
import { OrgRequired } from '../components/Organization';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider, useUser } from '../components/UserContext';
import config from '../config';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { apiService } from '../services/api-service';
import { gaScripts } from '../tracking/ga';
import {
  HubspotIdentify,
  hubspotScripts,
  HubspotTrackPageView,
} from '../tracking/hubspot';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const packId = url.searchParams.get('pack-id');
  if (!packId) {
    throw new Response('Not Found', { status: 404 });
  }

  const pack = (await apiService.gamePack.getGamePackById(packId)).data
    .gamePack;
  const product = (
    GamePackUtils.IsLive(pack)
      ? await apiService.product.getLiveProduct()
      : await apiService.product.getMostPopularProduct()
  ).data.product;

  if (!product) {
    throw new Response('Not Found', { status: 404 });
  }

  return {
    pack,
    product,
  };
}

export function useCheckoutLoaderData() {
  const data = useRouteLoaderData<typeof clientLoader>(
    $routeId('routes/_shell-client.checkout._shell')
  );
  if (!data) {
    throw new Error('data not found');
  }
  return data;
}

export function shouldRevalidate(args: ShouldRevalidateFunctionArgs) {
  const currentPackId = args.currentUrl.searchParams.get('pack-id');
  const nextPackId = args.nextUrl.searchParams.get('pack-id');
  if (currentPackId === nextPackId) {
    return false;
  }

  return args.defaultShouldRevalidate;
}

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts(), ...gaScripts()];
  },
};

function HubspotTracking() {
  const user = useUser();

  return (
    <>
      <HubspotIdentify email={user.email || ''} />
      <HubspotTrackPageView />
    </>
  );
}

function Bootstrap() {
  useBootIntercom(useInstance(() => ['/checkout']));

  return null;
}

export function Component() {
  const providers = [
    <GlobalBlockedAccess />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess />,
    <ConfirmCancelModalProvider />,
    <OrgRequired />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
      <GameCoverClip id='game-cover-clip' />
      <ConfirmCancelModalRoot />

      <Bootstrap />
      <HubspotTracking />
    </ProvidersList>
  );
}
